$SLIDER-HEIGHT = 600px;
$DOTS-SIZE = 15px;

.home-slider {
  height: $SLIDER-HEIGHT;
  overflow: hidden;
  position: relative;
  .slick-arrow {
    position: absolute;
    text-indent: -999em;
    overflow: hidden;
    width: 37px;
    height: 61px;
    top: calc(50% - 25px);
    z-index: 10;
    background-color: transparent;
    border: 0;
    outline: none;
    transition: opacity .2s ease;
    &.slick-prev {
      left: 25px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSItMjM3NS43MTIgLTE0MjAxLjk3OSA0NS4xMjEgNjkuNzI5Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDpub25lO3N0cm9rZTojZmZmO3N0cm9rZS13aWR0aDoycHg7fS5ie2ZpbHRlcjp1cmwoI2EpO308L3N0eWxlPjxmaWx0ZXIgaWQ9ImEiIHg9Ii0yMzc1LjcxMiIgeT0iLTE0MjAxLjk3OSIgd2lkdGg9IjQ1LjEyMSIgaGVpZ2h0PSI2OS43MjkiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PGZlT2Zmc2V0IGR5PSIzIiBpbnB1dD0iU291cmNlQWxwaGEiLz48ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSIzIiByZXN1bHQ9ImIiLz48ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwLjE2MSIvPjxmZUNvbXBvc2l0ZSBvcGVyYXRvcj0iaW4iIGluMj0iYiIvPjxmZUNvbXBvc2l0ZSBpbj0iU291cmNlR3JhcGhpYyIvPjwvZmlsdGVyPjwvZGVmcz48ZyBjbGFzcz0iYiIgdHJhbnNmb3JtPSJtYXRyaXgoMSwgMCwgMCwgMSwgMCwgMCkiPjxwYXRoIGNsYXNzPSJhIiBkPSJNMjQuNyw0OS40NTVsLTI1LTI1LjE2LDI1LTI1LjE2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjM2NSAtMTQxOTQuNDEpIi8+PC9nPjwvc3ZnPg==');
    }
    &.slick-next {
      left: calc(100% - 65px);
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSItNjY5LjAxMiAtMTQyMDEuOTc5IDQ1LjEyMSA2OS43MjkiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLXdpZHRoOjJweDt9LmJ7ZmlsdGVyOnVybCgjYSk7fTwvc3R5bGU+PGZpbHRlciBpZD0iYSIgeD0iLTY2OS4wMTIiIHk9Ii0xNDIwMS45NzkiIHdpZHRoPSI0NS4xMjEiIGhlaWdodD0iNjkuNzI5IiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiPjxmZU9mZnNldCBkeT0iMyIgaW5wdXQ9IlNvdXJjZUFscGhhIi8+PGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMyIgcmVzdWx0PSJiIi8+PGZlRmxvb2QgZmxvb2Qtb3BhY2l0eT0iMC4xNjEiLz48ZmVDb21wb3NpdGUgb3BlcmF0b3I9ImluIiBpbjI9ImIiLz48ZmVDb21wb3NpdGUgaW49IlNvdXJjZUdyYXBoaWMiLz48L2ZpbHRlcj48L2RlZnM+PGcgY2xhc3M9ImIiIHRyYW5zZm9ybT0ibWF0cml4KDEsIDAsIDAsIDEsIDAsIDApIj48cGF0aCBjbGFzcz0iYSIgZD0iTTI1LDUwLjMyLDAsMjUuMTYsMjUsMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTYzNC4zIC0xNDE0NC45NSkgcm90YXRlKDE4MCkiLz48L2c+PC9zdmc+');
    }
    &:hover {
      opacity: .5;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    margin: 0;
    z-index: 10;
    text-align: center;
    height: $DOTS-SIZE;
    li {
      display: inline-block;
      height: $DOTS-SIZE;
      margin-left: 7.5px;
      margin-right: 7.5px;
      &.slick-active {
        button {
          background $VIOLET_LIGHT;
        }
      }
      button {
        width: $DOTS-SIZE;
        height: $DOTS-SIZE;
        text-indent: -999em;
        overflow: hidden;
        border-radius: 50%;
        background: #fff;
        border: 1px solid $GREY;
        transition: background-color .2s ease;
        outline: none;
        &:hover {
          background $VIOLET_LIGHT;
        }
      }
    }
  }
}

.home-slide {
  height: $SLIDER-HEIGHT;
  background-size: cover;
  background-position: 50% 50%;
}

.home-slide__title {
  font-family:$FONT_SECOND;
}


.home-slide__content {
  position: absolute;
  left: 100px;
  top: 50%;
  transform: translateY(-50%);
  max-width: 480px;
}

.home-slide__title {
  margin-top: 0;
  font-size: 50px;
  line-height: 60px;
  color: #fff;
  text-transform: uppercase;
}

.home-slide__anons {
  font-size: 30px;
  color: #fff;
  line-height: 36px;
}

.home-slide__link {
  margin-top: 65px;
}

.slick-track {
  width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 580px) {
  .home-slider {
    height:300px;
  }
  .home-slide {
    height:300px;
  }

  .home-slide__content {
    left: 80px;
    max-width: 150px;
  }
  .home-slide__title {
    font-size:20px;
    line-height:22px;
  }
  .home-slide__anons {
    font-size:16px;
    line-height:18px;
  }
  .home-slide__content .home-slide__link {
    width:150px;
    height:30px;
    font-size:14px;
    line-height:30px;
    margin-top:20px;
  }

  .home-slider .slick-dots {
    display: none !important;
  }
  .home-slider .slick-arrow {
    width:26px;
    height:40px;
  }
}