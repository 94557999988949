.text-card {
  margin-bottom: 50px;
  position: relative;
  z-index: 1;

  &:nth-child(odd) {
    .text-card__img { float: left; }
    .text-card__content { float: right; }
    .text-card__link { margin-left: auto; }
    .text-card__img-link { padding: 30px 10px 60px 30px; }
    .text-card__img-link img { border-radius: 12px 0 12px 0; }
    .text-card__img-link:before { left: 30px; border-radius: 12px 0 12px 0; }
    /*&:before { left: 10px; border-radius: 12px 0 12px 0; }*/
    /*&:after {
      width: 138px;
      height: 128px;
      left: 10px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xNzg1MC4yNSAtMTMzMzQuMDc5IDEzNy4yNSAxMjcuNTc5Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDpub25lO3N0cm9rZTojYjhhMWM2O3N0cm9rZS13aWR0aDo3cHg7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNMCwwVjEyNC4wNzlIMTMzLjc1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTc4NDYuNzUgLTEzMzM0LjA3OSkiLz48L3N2Zz4=');
    }*/
  }

  &:nth-child(even) {
    .text-card__img { float: right; }
    .text-card__content { float: left; }
    .text-card__link { margin-right: auto; }
    .text-card__img-link { padding: 30px 30px 60px 10px; }
    .text-card__img-link img { border-radius: 0 12px 0 12px; }
    .text-card__img-link:before { right: 30px; border-radius: 0 12px 0 12px; }
    /*&:before { right: 10px; border-radius: 0 12px 0 12px; }*/
    /*&:after {
      width: 128px;
      height: 138px;
      right: 10px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xNjM1NyAtMTI2NTUuOTM0IDEyNy41NzkgMTM3LjI1Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDpub25lO3N0cm9rZTojYjhhMWM2O3N0cm9rZS13aWR0aDo3cHg7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNMCwwVjEyNC4wNzlIMTMzLjc1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTYzNTcgLTEyNTIyLjE4NCkgcm90YXRlKC05MCkiLz48L3N2Zz4=');
    }*/
  }

  /*&:before {
    content: '';
    display: block;
    width: 660px;
    height: 384px;
    background $BLUE_LIGHT;
    position: absolute;
    z-index: -1;
    top: 0;
  }*/

  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    bottom: 0;
  }
}

.text-card__content {
  padding-top: 25px;
}

.text-card__img-link {
  display: block;
  position: relative;
  /*&:before {
    content: '';
    display: block;
    width: calc(100% - 40px);
    height: calc(100% - 90px);
    position: absolute;
    top: 30px;
    background: black;
    opacity: 0;
    transition: opacity .3s ease;
  }*/

  img {
    width: 100%;
  }

  &:hover {
    &:before {
      opacity: .5;
    }
  }
}


.text-card__title {
  margin-top: 0;
  margin-bottom: 35px;
  font-family: "Times New Roman", serif;
  font-size: 50px;
  line-height: 55px;
  text-transform: uppercase;
}

.text-card__title-link {
  color: black;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  &:focus {
    color: black;
    text-decoration: none;
  }
  &:hover {
    color: $VIOLET_LIGHT;
    border-bottom: 1px solid $VIOLET_LIGHT;
    text-decoration: none;
    /*text-decoration: underline;*/
  }
}

.text-card__anons {
  color: #7C7C7C;
  line-height: 29px;
}

.text-card__link {
  margin-top: 50px;
}

.text-card__date {
  color: #7C7C7C;
  font-size: 22px;
  margin-bottom: 5px;
}


.text-card__img:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  background $BLUE_LIGHT;
  position: absolute;
  z-index: -1;
  top: 0;
}

.text-card:nth-child(odd) .text-card__img:before {
  left: 10px;
  border-radius: 12px 0 12px 0;
}

.text-card:nth-child(even) .text-card__img:before {
  right: 10px;
  border-radius: 0 12px 0 12px;
}

.text-card:nth-child(odd) .text-card__img:after {
  content: '';
  display: block;
  position: absolute;
  top: 66%;
  width: 20%;
  height: 30%;
  left: 10px;
  background-image: url('../../../individual/public/images/temp/border-right.png');
  background-repeat: no-repeat;
  background-position: 0 100%;
}

.text-card:nth-child(even) .text-card__img:after {
  content: '';
  display: block;
  position: absolute;
  top: 66%;
  right: 10px;
  width: 20%;
  height: 30%;
  background-image: url('../../../individual/public/images/temp/border-left.png');
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

@media screen and (max-width: 1360px) {
  .text-card:nth-child(odd) .text-card__img:before,
  .text-card:nth-child(even) .text-card__img:before {
    top:8px;
  }
  .text-card:nth-child(odd) .text-card__img:before {
    left:14px;
  }
  .text-card:nth-child(even) .text-card__img:before {
    right:14px;
  }
  .text-card:nth-child(odd) .text-card__img:after {
    left: 14px;
  }
  .text-card:nth-child(even) .text-card__img:after {
    right:14px;
  }
}

@media screen and (max-width: 920px) {
  .text-card__title {
    font-size: 30px;
    line-height: 34px;
  }

  .text-card__anons {
    font-size: 18px;
    line-height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .text-card__link {
    margin: 0 auto;
    margin-top: 40px;
  }
  
  .text-card__title {
    text-align: center;
  }

  .text-card .text-card__img-link {
    padding-bottom:26px !important;
  }

  .text-card__content {
    padding-top:0;
  }

  .text-card__content .text-card__title {
    display: none;
  }

  .text-card {
    padding-left:25px;
    padding-right:25px;
  }

  /*.text-card:nth-child(even) .text-card__img:after {
    right:0;
  }*/

  .text-card:nth-child(even) .text-card__img:after,
  .text-card:nth-child(odd) .text-card__img:after {
    content: none;
  }
}

@media screen and (max-width: 420px) {
  .text-card__anons {
    padding-left:15px;
    padding-right:15px;
  }
}


@media screen and (max-width: 320px) {
  .text-card__title {
    font-size: 30px;
    line-height: 32px;
  }
}

@media screen and (min-width: 768px){
  .text-card__title-small {
    display: none;
  }
}