.staff-list--mb {
  margin-bottom: -30px;
}

.staff-card-big {
  margin-bottom: 30px;
}

.staff-card-big__box {
  /*width: 520px;*/
  width: 100%;
  margin: 0 auto;
  display: block;
  position: relative;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    transition: opacity .3s ease;
  }
  &:hover {
    &:before { opacity: .5; }
    .staff-card-big__name,
    .staff-card-big__position {
      text-decoration: underline;
      color: $VIOLET_LIGHT;
    }
  }
}

.staff-card-big__info {
  height: 120px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255,.8);
  padding: 30px 80px;
  text-align: center;
}

.staff-card-big__name {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: bold;
  color: black;
}

.staff-card-big__position {
  font-size: 17px;
  line-height: 18px;
  font-style: italic;
  color: $VIOLET;
}

.staff-card-big__anons {
  /*width: 520px;*/
  width: 100%;
  height: 264px;
  overflow: hidden;
  margin: 0 auto;
  background $BLUE_LIGHT;
  padding: 30px 30px 40px 30px;
  font-size: 20px;
  color: #7C7C7C;
  line-height: 26px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.staff-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
}
.staff-card-big__box img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

@media screen and (max-width: 1620px) {
  .staff-card-big__info {
    padding: 10px 30px;
  }
}

@media screen and (max-width: 771px) {
  .staff-card-big__info {
    padding: 30px 80px;
  }
}

@media screen and (max-width: 580px) {
  .staff-card-big__info {
    padding: 20px 40px;
  }
}

@media screen and (max-width: 460px) {
  .staff-card-big__info {
    padding: 20px;
  }
}