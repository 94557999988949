

.staff-card-small__box {
  max-width: 343px;
  margin: 10px auto;
  display: block;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0;
    border-radius: 12px;
    transition: opacity .3s ease;
  }
  &:hover {
    &:before { opacity: .5; }
    .staff-card-small__name,
    .staff-card-small__position {
      text-decoration: underline;
      color: $VIOLET_LIGHT;
    }
  }
}

.staff-card-small__img {
  border-radius: 12px;
}

.staff-card-small__info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 95px;
  background: rgba(255,255,255,.8);
  padding: 10px 15px;
  text-align: center;
  border-radius: 0 0 12px 12px;
}

.staff-card-small__name {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  color: black;
}

.staff-card-small__position {
  font-size: 17px;
  line-height: 18px;
  font-style: italic;
  color: $VIOLET;
}

.staff-card-small__box img {
  border-radius: 12px;
}

.home-staff .home-staff__slider{
  /*overflow: hidden;*/
  position: relative;
  width: 90%;
  margin: 0 auto;
  .slick-arrow {
    position: absolute;
    text-indent: -999em;
    overflow: hidden;
    width: 37px;
    height: 61px;
    top: calc(50% - 25px);
    z-index: 10;
    background-color: transparent;
    border: 0;
    outline: none;
    transition: opacity .2s ease;
    &.slick-prev {
      left: -30px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSItMjM3NS43MTIgLTE0MjAxLjk3OSA0NS4xMjEgNjkuNzI5Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDpub25lO3N0cm9rZTojZmZmO3N0cm9rZS13aWR0aDoycHg7fS5ie2ZpbHRlcjp1cmwoI2EpO308L3N0eWxlPjxmaWx0ZXIgaWQ9ImEiIHg9Ii0yMzc1LjcxMiIgeT0iLTE0MjAxLjk3OSIgd2lkdGg9IjQ1LjEyMSIgaGVpZ2h0PSI2OS43MjkiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PGZlT2Zmc2V0IGR5PSIzIiBpbnB1dD0iU291cmNlQWxwaGEiLz48ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSIzIiByZXN1bHQ9ImIiLz48ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwLjE2MSIvPjxmZUNvbXBvc2l0ZSBvcGVyYXRvcj0iaW4iIGluMj0iYiIvPjxmZUNvbXBvc2l0ZSBpbj0iU291cmNlR3JhcGhpYyIvPjwvZmlsdGVyPjwvZGVmcz48ZyBjbGFzcz0iYiIgdHJhbnNmb3JtPSJtYXRyaXgoMSwgMCwgMCwgMSwgMCwgMCkiPjxwYXRoIGNsYXNzPSJhIiBkPSJNMjQuNyw0OS40NTVsLTI1LTI1LjE2LDI1LTI1LjE2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjM2NSAtMTQxOTQuNDEpIi8+PC9nPjwvc3ZnPg==');
    }
    &.slick-next {
      right: -30px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSItNjY5LjAxMiAtMTQyMDEuOTc5IDQ1LjEyMSA2OS43MjkiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLXdpZHRoOjJweDt9LmJ7ZmlsdGVyOnVybCgjYSk7fTwvc3R5bGU+PGZpbHRlciBpZD0iYSIgeD0iLTY2OS4wMTIiIHk9Ii0xNDIwMS45NzkiIHdpZHRoPSI0NS4xMjEiIGhlaWdodD0iNjkuNzI5IiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiPjxmZU9mZnNldCBkeT0iMyIgaW5wdXQ9IlNvdXJjZUFscGhhIi8+PGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMyIgcmVzdWx0PSJiIi8+PGZlRmxvb2QgZmxvb2Qtb3BhY2l0eT0iMC4xNjEiLz48ZmVDb21wb3NpdGUgb3BlcmF0b3I9ImluIiBpbjI9ImIiLz48ZmVDb21wb3NpdGUgaW49IlNvdXJjZUdyYXBoaWMiLz48L2ZpbHRlcj48L2RlZnM+PGcgY2xhc3M9ImIiIHRyYW5zZm9ybT0ibWF0cml4KDEsIDAsIDAsIDEsIDAsIDApIj48cGF0aCBjbGFzcz0iYSIgZD0iTTI1LDUwLjMyLDAsMjUuMTYsMjUsMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTYzNC4zIC0xNDE0NC45NSkgcm90YXRlKDE4MCkiLz48L2c+PC9zdmc+');
    }
    &:hover {
      opacity: .5;
    }
  }
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: 45px;
  }
  .staff-card-small__info {
    height:110px;
  }
  .staff-card-small__name {
    font-size:16px;
  }
  .staff-card-small__position {
    font-size:14px;
  }
}

@media screen and (max-width: 650px) {
  .title {
    font-size: 30px;
    margin-bottom: 30px;
  }

}

@media screen and (min-width: 768px) {
  .home-staff .home-staff__slider .slick-arrow.slick-next {
    right:-45px;
  }
  .home-staff .home-staff__slider .slick-arrow.slick-prev {
    left:-45px;
  }
}
