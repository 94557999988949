.footer {
  background: #3A3946;
  padding: 40px 0 20px;
  hr {
    width: 100%;
    margin: 20px auto 20px;
    border-color: #5F5F68;
  }
}
@media screen and (max-width: 767px) {
  .footer .footer-social {
    margin-bottom:20px;
  }

  .footer hr {
    display: none;
  }

  .footer-contacts {
    margin-top:20px;
  }
}
