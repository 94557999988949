.breadcrumbs {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 30px;
  font-size: 14px;

  a {
    text-decoration: none;
    color: $VIOLET_LIGHT;
    &:hover {
      text-decoration: underline;
    }
  }
  span.sep {
    color: $VIOLET_LIGHT;
    margin: 0 5px;
  }
  span:last-child {
    color: #BFA6D2;
  }
}

@media screen and (max-width: 960px) {
  .breadcrumbs {
    display: none;
  }
}
