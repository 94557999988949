.home-staff {
  background $BLUE_LIGHT;
  padding-top: 60px;
  padding-bottom: 60px;
}

.home-staff .all-staff-btn {
  margin:0 auto;
  margin-top:60px;
  font-family:$FONT_DEFAULT;
  font-size:16px;
  text-transform: none;
}

.home-staff .title {
  margin-bottom:60px;
}


@media screen and (max-width: 768px) {
  .page--home .home-staff {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .home-staff .all-staff-btn {
    margin-top:20px;
  }

  .home-staff .title {
    margin-bottom:20px;
  }
}

@media screen and (min-width: 1281px) {
  .home-staff .all-staff-btn {
    display: none;
  }
}