.page-pin {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  color: $BLACK;
  &--top { margin: 40px 0; }
  &--bot { margin-top: 40px; }
}

.pin {
  color: $BLACK;
  h1, h2, h3, h4, h5, h6 {
    color: black;
    font-family 'Times New Roman', serif;
    text-align: center;
    &:first-child {
      margin-top: 0;
    }
  }

  h2 { font-size: 43px; }
  h3 { font-size: 38px; }
  h4 { font-size: 33px; }
}
.pin img {
  border-radius: 10px;
  }

.page-pin--top {
  margin-bottom:30px;
  margin-top:0;
}

.page-backlink-wrapper {
  display: flex;
  justify-content:center;
}

.kir-list .text-card:last-child {
  margin-bottom:0;
}

@media screen and (max-width: 960px){
  .page-pin--top:first-child {
    margin-top:30px;
  }
  .kir-detail p:first-child {
    margin-top:30px;
  }
}

@media screen and (max-width: 950px) {
  .pin h2 {
    font-size:30px;
  }
}

@media screen and (max-width:650px) {
  .page-pin p {
    font-size:15px;
  }
}

@media screen and (max-width: 460px) {
  .pin h2 {
    font-size:28px;
  }
  .page-pin p {
    text-align: left !important;
  }
}