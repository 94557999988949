.service-detail {
  padding-top:0;
}


.service-detail-head {
  position: relative;
  margin-bottom: 102px;
  &:before {
    content: '';
    display: block;
    width: 83px;
    height: 76px;
    position: absolute;
    bottom: -70px;
    left: 10px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMTE5OC45IC04OTU4LjAzNCA4Mi45IDc1LjUzNCI+ICA8ZGVmcz4gICAgPHN0eWxlPiAgICAgIC5jbHMtMSB7ICAgICAgICBmaWxsOiByZ2JhKDAsMCwwLDApOyAgICAgICAgc3Ryb2tlOiAjYjhhMWM2OyAgICAgICAgc3Ryb2tlLXdpZHRoOiA3cHg7ICAgICAgfSAgICA8L3N0eWxlPiAgPC9kZWZzPiAgPHBhdGggaWQ9IlBhdGhfMTA3IiBkYXRhLW5hbWU9IlBhdGggMTA3IiBjbGFzcz0iY2xzLTEiIGQ9Ik0wLDBWNzIuMDM0SDc5LjQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMTE5NS40IC04OTU4LjAzNCkiLz48L3N2Zz4=');
  }
}


.service-detail-head  .page-backlink-wrapper {
  margin-bottom: 0;
}

/*.service-detail-head__left {
  padding-left: 60px;
}*/

.service-detail-head__left {
  width:30%;
  float: left;
  padding-right:60px;
  padding-bottom:30px;
}

.service-detail-head__img {
  /*width: 470px;*/
  position: relative;
  padding-top:40px;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 20px;
    right: -25px;
    border-radius: 12px;
    background $BLUE_LIGHT;
  }

  img {
    border-radius: 12px;
  }
}

/*.service-detail-head__main img {
  display block;
  float: left;
  max-width: 30%;
  height: auto;
  border-radius: 12px;
  position: relative;
}

.service-detail-head__main img:before{
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 20px;
  right: -25px;
  border-radius: 12px;
  background $BLUE_LIGHT;
}*/

.service-detail-head__right {
  padding-left: 100px;
  padding-right: 200px;
  padding-top: 60px;
}

.service-detail-head__decor {
  float: right;
  display: block;
  width: 250px;
  height: 6px;
  background: #E4E4E4;
  &:before {
    content: '';
    display: block;
    width: 80px;
    height: 6px;
    background:#B8A1C6;
    position: absolute;
    top: 0;
    right: 170px;
  }
  &:after {
    content: '';
    display: block;
    width: 6px;
    height: calc(100% + 70px);
    background: #E4E4E4;
    position: absolute;
    right: 0;
    top: 0;
  }
}


.service-face__nav {
  margin-top: 40px;
  padding-left: 60px;
}

.service-face__list {
  list-style-type: none;
  margin: 0;
  /*columns: 4;*/
  display: flex;
  flex-flow: row wrap;
  li {
    width: 25%;
    a {
      padding-left: 12px;
      position: relative;
      &:before {
        content: '-';
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}

/*.services-list .text-card:last-child {
  margin-bottom:-20px;
}*/


.service-detail__button {
  display: block;
  margin-top: 30px;
  width: 360px;
  height: 90px;
  border: 0;
  background $VIOLET_LIGHT;
  border-radius: 4px;
  font-size: 20px;
  color: white;
  font-size: 20px;
  font-family "Tahoma", Arial, sans-serif;
  margin-bottom: 30px;
  &:hover {
    background $VIOLET;
  }
}


.price-list {
  .title {
    text-transform: none;
    margin-bottom:50px;
  }
  & + .pin {
    margin-top: 25px;
  }
}

.price-list__list {
  list-style-type: none;
  margin: 0;
}


.price-list__item {
  margin-bottom: 3px;
  color: black;
  font-family:$FONT_DEFAULT;
  &:nth-child(odd) {
    background: rgba(184, 161, 198,.25);
  }
  &:nth-child(even) {
    background: rgba(184, 161, 198,.43);
  }
  &:hover {
    background: #B8A1C6;
    color: white;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:before, &:after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
  & > div {
    padding: 20px 40px;
    float: left;
    font-size: 20px;
  }
}

.price-list__list .price-list__item .price-list__label {
  padding-bottom:0;
}

@media screen and (max-width: 1639px) {
  .service-detail__button {
    width:300px;
    height:65px;
  }
}

@media screen and (max-width: 1600px) {
  .service-detail-head__right {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 40px;
  }
}

@media screen and (max-width: 1280px) {
  .service-detail__button {
    width:246px;
    height:50px;
  }
  .service-face__list li {
    width:33%;
  }
  .service-detail-head__left {
    width:40%;
  }
}

@media screen and (max-width: 1024px) {
  .services-bottom-text {
    text-align: center;
    padding-left: 20px;
    padding-right:20px;
  }
}

@media screen and (max-width: 991px){
  .service-detail-head__left {
    width:50%;
  }
}

@media screen and (max-width: 960px){
  .pin.service-detail-head__right{
    padding-bottom: 0;
  }

  .service-detail {
    padding-top:30px;
  }

  .services-list {
    margin-top:30px;
  }
}

@media screen and (max-width: 768px) {
  .service-face__list li {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {

  .service-detail-head__left  {
    margin-top:30px;
  }


  .service-detail-head__decor {
    margin-right:30px;
    width:220px;
  }

  .service-detail-head__decor:after {
    right:24px;
  }

  .service-detail-head__decor {
    display: none;
  }

  .service-detail-head:before {
    display: none;
  }

  .service-detail__button {
    margin:30px auto;
  }
  .service-detail-head__left img {
    margin-left: 20px;
  }
  .service-detail-head__right {
    padding-left:20px;
  }
  .service-detail-head__img:before {
    right:-45px;
  }
}

@media screen and (max-width: 667px) {
  .service-detail-head__left {
    width:45%;
  }
}

@media screen and (max-width: 640px) {
  .service-detail-head__left {
    width:100%;
    float: none;
    padding-right:0;
  }
  .service-detail-head__img:before {
    content: none;
  }
  .service-detail-head__img img {
    display: block;
    margin:0 auto;
  }
  .service-detail-head__left img {
    padding-left:0;
  }
}

@media screen and (max-width: 580px) {
  .service-detail-head__img:before {
    content: none;
  }
  .service-detail-head__img {
    padding-top:0;
  }
  .service-detail-head__left {
    padding-left:0;
  }
}

@media screen and (max-width: 490px){
  .service-face__list {
    text-align: left;
  }
  .service-detail-head__right {
    padding:30px;
  }
  .service-face__nav {
    padding-left:30px;
  }
}
@media screen and (max-width: 400px) {
  .service-face__list li {
    width:100%;
  }
}