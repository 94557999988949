.error-page {
  min-height: 100vh;
  padding-top: 0;
  background url('../images/error-page.jpg') no-repeat 65% 50%;
  background-size: cover;
  .container {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #7C7C7C;
  }
}

.error-page__title {
  margin-top: 0;
  margin-bottom: 40px;
  font-family 'Times New Roman', serif;
  font-size: 52px;
  font-style: italic;
  color: #fff;
}
