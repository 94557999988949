.news-detail {
  &:after, &:before {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}

.news-detail__picture {
  float: left;
  margin: 0 45px 20px 0;
  border-radius: 12px;
}

.news-detail__date {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-family: 'Segoe UI', Arial, sans-serif;
  align-self: flex-start;
}


h2.news-detail__title {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}

.news-list .text-card__date {
  margin-bottom:25px;
}

@media screen and (max-width: 990px) {
  .news-detail{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 960px) {
  .news-list {
    padding-top:20px;
    padding-bottom:40px;
  }

  .news-detail {
    margin-top:30px;
  }
}