.gallery-main {
  margin-bottom: 30px;
}

.gallery-main img {
  border-radius: 10px;
}

.gallery-title {
  margin-bottom: 20px;
  text-transform: none;
}

.gallery-item {
  display: block;
  /*width: 518px;*/
  margin: 0 auto 25px;
  border-radius: 10px;
  overflow: hidden;
}

.gallery-item img {
  border-radius: 10px;
}

.fancybox-outer {
  position: relative !important;
  overflow-y: visible;
}

.fancybox-inner {
  position: relative !important;
}

.fancybox-skin {
  padding-bottom:40px !important;
  border-radius: 0;
}

.fancybox-title {
  text-align: left !important;
  left: 0 !important;
  bottom: 7px !important;
  margin-bottom:0 !important;
}

.fancybox-title span {
  background-color: #fff !important;
  font-family:$FONT_DEFAULT !important;
  font-size:16px !important;
  color: #7C7C7C !important;
  text-shadow: none !important;

}

.fancybox-nav span {
  visibility: visible;
}

.fancybox-next span {
  background-image: none;
  background-repeat: no-repeat;
  background-position: center;
}

.fancybox-prev span {
  background-image: none;
  background-repeat:no-repeat;
  background-position: center;
}

.fancybox-close {
  background-image: url("../images/temp/fancybox-close.png");
  background-repeat:no-repeat;
  background-size: contain;
  top: -30px;
  right: -30px;
  width:20px;
  height:20px;
}

.fancybox-next span {
  right: -43px;
}

.fancybox-next span:before {
  content: "";
  left: 54%;
  position: absolute;
  top: 46%;
  background-image: url("../images/temp/next.png");
  background-repeat:no-repeat;
  background-position: center;

  width:25px;
  height:50px;
}

.fancybox-prev span {
  left: -70px;
}

.fancybox-prev span:before {
  content: "";
  left: 46%;
  position: absolute;
  top: 46%;
  background-image: url("../images/temp/prev.png");
  background-repeat:no-repeat;
  width:25px;
  height:50px;
}

.fancybox-title-float-wrap span.child {
  white-space: normal;
}

@media screen and (max-width: 720px) {
  .fancybox-close {
    right: 0;
  }
  .fancybox-next span {
    right: 20px;
  }
  .fancybox-prev span {
    left: -5px;
  }
}

@media screen and (max-width: 420px) {
  .gallery-item {
    margin-bottom: 10px;
  }

}
