.logo {

}

.logo__link {
  display: block;
  max-width: 300px;
  margin: 0 auto;
  margin-top: 6px;
  transition: opacity .3s ease;
  &:hover {
    opacity: .75;
  }
}

.logo__link img {
  width: 238px;
  display: block;
  /*margin:0 auto;*/
}
/*@media screen and (max-width: 1280px){
  .logo__link img {
    width:240px;
    margin:0 auto;
  }
}*/
@media screen and (max-width: 1199px) {
  .logo__link img {
    margin:0 auto;
  }
}

@media screen and (max-width: 650px){
  .logo__link {
    margin-top:10px;
    margin-bottom:10px;
  }
  .logo__link img {
    width:145px;
  }
}

