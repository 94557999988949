$BUTTON-HEIGHT_DEFAULT = 40px;
$BUTTON-HEIGHT_MEDIUM = 50px;
$BUTTON-WIDTH_DEFAULT = 200px;
$BUTTON-WIDTH_MEDIUM = 210px;
$BUTTON-BORDER-WIDTH_DEFAULT = 1px;

.button {
  display: block;
  border: $BUTTON-BORDER-WIDTH_DEFAULT solid transparent;
  background: transparent;
  border-radius: 4px;
  text-transform: uppercase;
  font-family: "Segoe UI", $FONT_DEFAULT;
  user-select: none;
  text-align: center;
  transition: box-shadow .1s ease, background-color .2s ease, border-color .2s ease;
  &--size-default {
    width: $BUTTON-WIDTH_DEFAULT;
    height: $BUTTON-HEIGHT_DEFAULT;
    font-size: 15px;
    line-height: $BUTTON-HEIGHT_DEFAULT - ($BUTTON-BORDER-WIDTH_DEFAULT * 2);
  }
  &--size-medium {
    width: $BUTTON-WIDTH_MEDIUM;
    height: $BUTTON-HEIGHT_MEDIUM;
    font-size: 16px;
    line-height: $BUTTON-HEIGHT_MEDIUM - ($BUTTON-BORDER-WIDTH_DEFAULT * 2);
  }
  &--color-grey {
    border-color: $GREY;
    color: $GREY;
    &:focus {
      text-decoration: none;
      color: $GREY;
    }
    &:hover {
      border-color: $VIOLET;
      background: $VIOLET;
      color: white;
    }
  }
  &--color-white {
    border-color: white;
    color: white;
    &:focus {
      text-decoration: none;
      color: white;
    }
    &:hover {
      border-color: $VIOLET_LIGHT;
      background: $VIOLET_LIGHT;
      color: white;
    }
  }
  &--align-right {
    margin-left: auto;
    width:146px;
    height:30px;
    line-height:28px;
    font-size:12px;
  }
  &--align-center { margin-left: auto; margin-right: auto; }

  &:active {
    box-shadow: inset 0 0 5px 1px rgba(0,0,0,0.3);
    outline: none;
  }
  &:hover {
    outline: none;
    text-decoration: none;
  }
}
