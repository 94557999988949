.page {
  padding-top: 91px;
  min-height: calc(100vh - 302px);
  &--inside {
    padding-bottom: 0;
  }
}

.title {
  font-size: 50px;
  margin-bottom: 70px;
  margin-top: 0;
  text-align: center;
  font-family: "Times New Roman", serif;
  text-transform: uppercase;
}

.page-map {
  height: 650px;
  background: lightgrey;
  &:last-child {
    margin-bottom: 0;
  }
}

.page-backlink {
  font-size: 16px;
  font-family 'Open Sans', Arial, sans-serif;
  line-height: 40px;
  display: inline-block;
  margin-top: 50px;
  margin-bottom: 50px;
  color: $VIOLET;
  text-decoration: none;
  padding-left: 50px;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  &:before {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $VIOLET_LIGHT;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xOTQwLjcwNyAtMTA1MjAuMzU0IDIwLjcwNyAyMC43MDciPjxkZWZzPjxzdHlsZT4uYXtmaWxsOm5vbmU7c3Ryb2tlOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yMzMwIC0xMjYyMCkiPjxwYXRoIGNsYXNzPSJhIiBkPSJNNzA2MCw1NTUwbC0xMCwxMCwxMCwxMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTY2NjAgLTM0NTApIi8+PHBhdGggY2xhc3M9ImEiIGQ9Ik03MDcwLDU1NjBoLTIwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjY2MCAtMzQ1MCkiLz48L2c+PC9zdmc+');
    background-size: 20px;
    background-position: 50%;
    background-repeat: no-repeat;
    transition: background-color .3s ease;
  }

  &:hover {
    text-decoration: underline;
    color: $VIOLET;
    &:before {
      background-color: $VIOLET;
    }
  }
}

.kir-list {
  padding-bottom:40px;
  /*padding-top:40px;*/
}

.top {
  position: fixed;
  bottom: 40px;
  left: -120px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #72448d;
  background-image: url("../../../individual/public/images/temp/top.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  cursor: pointer;
  transition: all .5s ease;
  z-index: 999;
}

.top.active {
  left: 20px;
}

@media screen and (max-width: 768px) {
  .home-staff h2.title {
    margin-bottom:20px;
  }
  .home-staff {
    padding-top:35px;
  }
}

@media screen and (max-width: 650px) {
  .page {
    padding-top:70px;
  }
}

