$PAGINATION-SIZE = 40px;

.navN {
  text-align: center;
  list-style-type: none;
  margin: 0;
  margin-top: 30px;
  li {
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
    margin-right: 7px;
    &.on a {
      background-color: $VIOLET;
      border-color: $VIOLET;
      color: white;
    }
    a {
      width: $PAGINATION-SIZE;
      height: $PAGINATION-SIZE;
      display: block;
      border: 1px solid $VIOLET_LIGHT;
      text-align: center;
      line-height: $PAGINATION-SIZE - 2px;
      font-size: 20px;
      background-position: 50%;
      background-size: 20px;
      background-repeat: no-repeat;
      &:hover {
        text-decoration: none;
        background-color: $VIOLET;
        border-color: $VIOLET;
        color: white;
      }
    }
  }

  .navN-prev {
    a {
      background-color: $VIOLET_LIGHT;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii01ODAwLjcwNyAtMTA3NTUuMzU0IDIwLjcwNyAyMC43MDciPiAgPGRlZnM+ICAgIDxzdHlsZT4gICAgICAuY2xzLTEgeyAgICAgICAgZmlsbDogbm9uZTsgICAgICAgIHN0cm9rZTogI2ZmZjsgICAgICB9ICAgIDwvc3R5bGU+ICA8L2RlZnM+ICA8ZyBpZD0iR3JvdXBfOTMiIGRhdGEtbmFtZT0iR3JvdXAgOTMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01MzkwIC04NjM1KSByb3RhdGUoMTgwKSI+ICAgIDxwYXRoIGlkPSJQYXRoXzU3IiBkYXRhLW5hbWU9IlBhdGggNTciIGNsYXNzPSJjbHMtMSIgZD0iTTcwNTAsNTU1MGwxMCwxMC0xMCwxMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTY2NTAgLTM0NTApIi8+ICAgIDxwYXRoIGlkPSJQYXRoXzU4IiBkYXRhLW5hbWU9IlBhdGggNTgiIGNsYXNzPSJjbHMtMSIgZD0iTTcwNTAsNTU2MGgyMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTY2NjAgLTM0NTApIi8+ICA8L2c+PC9zdmc+');
    }
  }
  .navN-next {
    a {
      background-color: $VIOLET_LIGHT;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii01ODAwIC0xMDc1NS4zNTQgMjAuNzA3IDIwLjcwNyI+ICA8ZGVmcz4gICAgPHN0eWxlPiAgICAgIC5jbHMtMSB7ICAgICAgICBmaWxsOiBub25lOyAgICAgICAgc3Ryb2tlOiAjZmZmOyAgICAgIH0gICAgPC9zdHlsZT4gIDwvZGVmcz4gIDxnIGlkPSJHcm91cF85MyIgZGF0YS1uYW1lPSJHcm91cCA5MyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTYxOTAgLTEyODU1KSI+ICAgIDxwYXRoIGlkPSJQYXRoXzU3IiBkYXRhLW5hbWU9IlBhdGggNTciIGNsYXNzPSJjbHMtMSIgZD0iTTcwNTAsNTU1MGwxMCwxMC0xMCwxMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTY2NTAgLTM0NTApIi8+ICAgIDxwYXRoIGlkPSJQYXRoXzU4IiBkYXRhLW5hbWU9IlBhdGggNTgiIGNsYXNzPSJjbHMtMSIgZD0iTTcwNTAsNTU2MGgyMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTY2NjAgLTM0NTApIi8+ICA8L2c+PC9zdmc+');
    }
  }
}
