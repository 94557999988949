.header {
  background: $BG_DEFAULT;
  padding-top: 0;
  padding-bottom: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: box-shadow .4s ease;
  z-index: 100;
  &.fix {
    box-shadow: 0 3px 6px 0 rgba(0,0,0,.28);
  }
}
.header.fix {
  background: rgba(255,255,255,0.9);
}

.header-center {
  padding-top: 11px;
  padding-bottom: 0px;
}

.header-right {
  padding-top: 5px;
  padding-bottom: 5px;
}

.header-formodal {
  margin-top: 10px;
}

.toggle-menu {
  width: 30px;
  height: 14px;
  position: absolute;
  top: 35px;
  margin-top: 0;
  left:50%;
  z-index: 2;
}

.toggle-menu span:after, .toggle-menu span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 9px;
}

.toggle-menu span:after {
  top: 18px;
}

.toggle-menu span {
  position: relative;
  display: block;
}

.toggle-menu span, .toggle-menu span:after, .toggle-menu span:before {
  width: 100%;
  height: 2px;
  background-color: #6E6E6E;
  transition: all 0.3s;
  backface-visibility: hidden;
  border-radius: 2px;
}

.toggle-menu.on span {
  background-color: transparent;
}

.toggle-menu.on span:before {
  transform: rotate(45deg) translate(-1px, 0px);
}

.toggle-menu.on span:after {
  transform: rotate(-45deg) translate(6px, -7px);
}

.hidden-menu {
  display: none;
}

.hidden-menu ul {
  list-style: none;
  margin: 30px 0;
  margin-top:0;
}

.hidden-menu ul li {
  margin-bottom: 10px;
  text-align: center;
  margin-left: 15px;
}

.social__link--search img {
  margin-top: -8px;
}

.social--align-center,
.button--align-center{
  text-align: center;
}

.hidden-menu .social__link--search img {
  margin-top: -45px;
}

.hidden-search {
  margin-top:36px;
}

.hidden-menu .header-right .social__list-item:first-child {
  display: none;
}

.hidden-menu .header-right {
  margin-bottom:30px;
}

.hidden-menu ul li {
  margin-left:0;
}

@media (max-width: 991px) {
  .header-right {
    display: none;
  }
  .hidden-menu .header-right {
    display: block;
  }
  .social__link--search img {
    margin-top: -28px;
    max-width:138%;
  }
  .toggle-menu {
    top:36px;
    right:15px;
    left:auto;
  }
}

@media (min-width: 992px) {
  .hidden-search {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .hidden-search {
    margin-top:18px;
  }

  .toggle-menu {
    top:23px;
  }
  .social__link--search img {
    margin-top: -12px;
  }
}

@media screen and (min-width: 1640px) {
  .header-right {
    padding-right:56px;
  }
}