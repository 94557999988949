#fosWindow {
  background-color: #F6F6F6;
  width:650px;
  padding:32px 40px 50px;
  position: relative;
}

#fosWindow .box-modal_title {
  font-size:28px;
  font-weight:400;
  color: #000;
  text-transform: uppercase;
  margin-bottom:30px;
  font-family:"Times New Roman", serif;
}

#fosWindow .field label,
#fosWindow .field .file-hint,
#fosWindow .radio label {
  font-family:$FONT_DEFAULT;
  font-size:18px;
  font-weight: 400;
  color: #47515A;
}

#fosWindow .field label {
  margin-bottom:15px;
}

#fosWindow form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

#fosWindow form .field {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom:11px;
}

#fosWindow form .field:nth-child(5){
  width:100%;
}

#fosWindow form .field input.inp {
  width:270px;
  height: 50px;
  border: 1px solid #D1D1D1;
  padding-left:15px;
}

#fosWindow form .field .errortext {
  width:270px;
}

#fosWindow form .field input[type="file"] {
  width:100px;
  height:auto;
  padding-left:0;
  border none;
}

#fosWindow form .field textarea.inp2 {
  width:570px;
  height: 129px;
  border: 1px solid #D1D1D1;
  padding-left:15px;
  padding-top:8px;
}

#fosWindow form select {
  position: relative;
  width:270px;
  height: 50px;
  border: 1px solid #D1D1D1;
  appearance: none!important;
  background-image: url("../images/temp/select.png");
  background-position: 100% center;
  background-repeat: no-repeat;
  cursor: pointer;
  padding-left: 20px;
  font-size: 16px;
  color: #47515A;
}

#fosWindow form select::-ms-expand {
  display: none;
}

#fosWindow form .field input[type="file"] {
  width:440px;
}

#fosWindow .field .file-hint {
  font-size:12px;
  color: #95989A;
  margin-top:8px;
}

#fosWindow form .radio {
  width:100%;
}

#fosWindow form .radio input {

  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 7px;
}

#fosWindow form .radio label {
  font-size:16px;
  position: relative;
  padding: 0 0 0 35px;
  cursor: pointer;
}

.radio input + label:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #95989A;
  border-radius: 50%;
  background: #FFF;
}

.radio input + label:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #000;
  opacity: 0;
  transition: .2s;
}

.radio input:checked + label:after {
  opacity: 1;
}

#fosWindow form .pcheck input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 20px;
}

#fosWindow form .pcheck label {
  font-family:$FONT_DEFAULT;
  font-size:16px;
  font-weight: 400;
  display: inline;
  position: relative;
  padding: 0 0 0 26px;
  cursor: pointer;
}

#fosWindow form .pcheck label:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 19px;
  border-radius: 0;
  background: #fff;
  border: 1px solid #95989A;
  transition: .2s;
}

#fosWindow form .pcheck label:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 0;
  background: #000;
  opacity: 0;
  transition: .2s;
}

#fosWindow form .pcheck input:checked + label:after {
  opacity:1;
}

#fosWindow form .pcheck label a {
  display: inline-block;
  margin-left:27px;
  text-decoration: underline;
}

#fosWindow form button {
  width:300px;
  height: 80px;
  margin-top: 34px;
  background-color: $VIOLET_LIGHT;
  font-family:"Tahoma", "Arial", "Hevetica", sans-serif;
  font-size:20px;
  text-transform: uppercase;
  color: #fff;
  border: none;
  border-radius: 4px;
  outline: none;
}

.box-modal_close {
  position: absolute;
  width:20px;
  height:20px;
  background-image: url("../images/temp/close.png");
  top:20px;
  right:20px;
  cursor: pointer;
}

.box-modal_close:hover {
  background-image: url("../images/temp/close-violet.png");
}

#fosWindow form .errortext,
#fosWindow form .errorMessage{
  color: red;
  font-size:16px;
  margin-top:8px;
}
  
#msgWindow {
  background-color: #F6F6F6;
  width:590px;
  margin: 0 auto;
  padding: 34px 70px 40px 40px;
  position: relative;
  font-family:"Open Sans", sans-serif;
  font-size:16px;
  color: #7C7C7C;
  margin-bottom:35px;
}

#msgWindow .box-modal_title {
  font-family: "Times New Roman", serif;
  font-size:30px;
  text-transform: uppercase;
  margin-bottom:30px;
  margin-top: 0;
  color: #000;
}

#msgWindow button {
  margin-top: 36px;
  width:200px;
  height:50px;
  background-color: #9972B8;
  color: #fff;
  border: none;
  font-family:"Tahoma", "Helvetica", sans-serif;
  font-size:20px;
  border-radius: 4px;
  outline: none;
}

.search-form__field,
.navN {
  width:100%;
}

.navN li{
  margin-bottom:20px;
}

.pcheck label.required span {
  color: #72448d;
}

@media screen and (max-width: 991px) and (min-width 961px){
  #fosWindow form .pcheck label a {
    margin-left:5px;
  }
}

@media screen and (max-width: 960px){
  #fosWindow {
    width:450px;
  }
  #fosWindow form .field textarea.inp2 {
    width:350px;
  }
  #fosWindow form .field input[type="file"] {
    width:350px;
  }
  #fosWindow form .field input.inp, #fosWindow form .field textarea.inp2, #fosWindow form select {
    width:350px;
  }
  #fosWindow form .field .errortext {
    width:350px;
  }
  #fosWindow form button {
    width:300px;
    height:50px;
    margin: 0 auto;
    margin-top:30px;
  }
  #fosWindow .box-modal_title {
    font-size:22px;
  }
  .pcheck {
    margin-top:20px;
  }
}

@media screen and (max-width: 690px) {
  #msgWindow {
    width:300px;
  }

  #msgWindow .box-modal_title {
    font-size:22px;
  }
}

@media screen and (max-width: 600px) {
  #fosWindow {
    width:350px;
  }
  #fosWindow form .field textarea.inp2 {
    width:280px;
  }
  #fosWindow form .field input[type="file"] {
    width:280px;
    font-size:14px;
  }
  #fosWindow form .field input.inp, #fosWindow form .field textarea.inp2, #fosWindow form select {
    width:280px;
  }
  #fosWindow form .field .errortext {
    width:280px;
  }
}

@media screen and (max-width: 420px) {
  #fosWindow {
    width:300px;
  }
  #fosWindow form .field input.inp, #fosWindow form .field textarea.inp2, #fosWindow form select {
    width:200px;
    height:30px;
  }
  #fosWindow form .field .errortext {
    width:200px;
  }
  #fosWindow form .field textarea.inp2 {
    width:200px;
    height:80px;
  }
  #fosWindow form .field input[type="file"] {
    width:200px;
    font-size:12px;
  }
  .arcticmodal-container_i2 {
    padding: 24px 0;
  }

  .g-recaptcha {
    width:200px;
  }
  .pcheck a {
    width:150px;
  }
}

@media screen and (min-width: 992px){
  .pcheck {
    margin-left: 15px;
  }
}