.search {
  margin-top:30px;
}

.search-form {
  background $BLUE_LIGHT;
  margin-bottom: 15px;
  padding: 60px;
  border-radius: 12px;
}

.search-form__field {
  width: 1070px;
  margin: 0 auto;
  position: relative;
}

.search-form__input {
  width: 100%;
  height: 50px;
  border: 1px solid #C4A6D6;
  padding: 0 20px;
  font-size: 20px;
  font-family "Segoe UI", Arial, sans-serif;
  background #FFFFFF
  &::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease; color: #95989A;}
  &::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease; color: #95989A;}
  &:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease; color: #95989A;}
  &:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease; color: #95989A;}
  &:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
  &:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
  &:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
  &:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}
}

.search-form__submit {
  background-color: transparent;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii01NTE1LjUxOSAtMTM5NTQuODg1IDM2LjE2MiAzNi4wMDgiPiAgPGRlZnM+ICAgIDxzdHlsZT4gICAgICAuY2xzLTEsIC5jbHMtNCB7ICAgICAgICBmaWxsOiBub25lOyAgICAgIH0gICAgICAuY2xzLTEgeyAgICAgICAgc3Ryb2tlOiAjNzI0NDhkOyAgICAgICAgc3Ryb2tlLXdpZHRoOiAycHg7ICAgICAgfSAgICAgIC5jbHMtMiB7ICAgICAgICBmaWxsOiAjNzI0NDhkOyAgICAgIH0gICAgICAuY2xzLTMgeyAgICAgICAgc3Ryb2tlOiBub25lOyAgICAgIH0gICAgPC9zdHlsZT4gIDwvZGVmcz4gIDxnIGlkPSLQuNC60L7QvdC60LBf0L/QvtC40YHQutCwIiBkYXRhLW5hbWU9ItC40LrQvtC90LrQsCDQv9C+0LjRgdC60LAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02NTA3LjU5IC0xMjk4My4xOCkgcm90YXRlKC00NSkiPiAgICA8ZyBpZD0iRWxsaXBzZV8xIiBkYXRhLW5hbWU9IkVsbGlwc2UgMSIgY2xhc3M9ImNscy0xIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMzc2IDI3KSI+ICAgICAgPGVsbGlwc2UgY2xhc3M9ImNscy0zIiBjeD0iMTIuNTk5IiBjeT0iMTIuODYyIiByeD0iMTIuNTk5IiByeT0iMTIuODYyIi8+ICAgICAgPGVsbGlwc2UgY2xhc3M9ImNscy00IiBjeD0iMTIuNTk5IiBjeT0iMTIuODYyIiByeD0iMTEuNTk5IiByeT0iMTEuODYyIi8+ICAgIDwvZz4gICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV8xMTUiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDExNSIgY2xhc3M9ImNscy0yIiB3aWR0aD0iMy42IiBoZWlnaHQ9IjExLjk0MyIgcng9IjEuOCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTM4Ny4xOTUgNTEuNDAyKSIvPiAgPC9nPjwvc3ZnPg==');
  background-position: 50% 40%;
  background-repeat: no-repeat;
  background-size: 30px;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
}


.search-result {
  margin-bottom: 17px;
  text-align: center;
  font-size: 30px;
  font-family: "Times New Roman", serif;
  color: black;
}

.search-list {
  margin: 0;
  padding-left: 30px;
  color: #7C7C7C;
}

.search-item {
  margin-bottom: 30px;
  & > div {
    padding-left: 10px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.search-item__link {
  font-size: 16px;
  overflow: hidden;
}

.search-item__text,
.search-item__title {
  margin-bottom: 5px;
}

input::-ms-clear {
  display: none;
}


/* Autocomplete */

.autocomplete-suggestions { border: 1px solid #999; background: #FFF; overflow: auto; }
.autocomplete-suggestion { padding: 2px 5px; white-space: nowrap; overflow: hidden; }
.autocomplete-selected { background: #F0F0F0; }
.autocomplete-suggestions strong { font-weight: normal; color: #9972B8; }
.autocomplete-group { padding: 2px 5px; }
.autocomplete-group strong { display: block; border-bottom: 1px solid #000; }

@media screen and (max-width: 500px) {
  .search .search-form {
    padding:20px;
    border-radius: 0;
  }
  .search-form__input::placeholder {
    font-size:14px;
  }
}
