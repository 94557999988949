// LIBS
@import "../libs/css/normalize.css";
@import "../libs/bootstrap/css/bootstrap.min.css";
@import "../libs/fancybox/jquery.fancybox.min.css";
@import "../libs/slick/slick.css";


// GLOBAL
@import "globals/var.styl";
@import "globals/base.styl";



// COMPONENTS
@import "components/header/header.base.styl";
@import "components/header/header.logo.styl";
@import "components/header/header.contacts.styl";

@import "components/home/home.slider.styl";
@import "components/home/home.services.styl";
@import "components/home/home.staff.styl";
@import "components/home/home.map.styl";

@import "components/page/page.base.styl";
@import "components/page/page.header.styl";
@import "components/page/page.pin.styl";
@import "components/page/page.about.styl";
@import "components/page/page.contacts.styl";
@import "components/page/page.staff.styl";
@import "components/page/page.news.styl";
@import "components/page/page.services.styl";
@import "components/page/page.gallery.styl";
@import "components/page/page.error.styl";
@import "components/page/page.search.styl";

@import "components/modules/nav.styl";
@import "components/modules/button.styl";
@import "components/modules/social.styl";
@import "components/modules/text-card.styl";
@import "components/modules/staff-card-small.styl";
@import "components/modules/staff-card-big.styl";
@import "components/modules/staff-sertificate.styl";
@import "components/modules/breadcrumbs.styl";
@import "components/modules/pagination.styl";
@import "components/modules/license.styl";
@import "components/modules/fos.styl";

@import "components/footer/footer.base.styl";
@import "components/footer/footer.logo.styl";
@import "components/footer/footer.contacts.styl";



