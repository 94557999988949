* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: color .1s linear,
              background-color .1s linear,
              text-decoration .1s linear,
              border .1s linear;
}

body {
  background: $BG_DEFAULT;
  -webkit-font-smoothing: antialiased;
  font-family: $FONT_DEFAULT;
  font-size: 18px;
  line-height: $LINE-HEIGHT_DEFAULT;
  color: $FONT-COLOR_DEFAULT;
  overflow-x: hidden;
  overflow-y: scroll !important;
}

a {
  color: $LINK-COLOR;
  text-decoration: none;
  &:focus {
    color: $LINK-COLOR;
    text-decoration: none;
  }
  &:hover {
    color: $LINK-COLOR_HOVER;
    text-decoration: underline;
  }
}

p:last-child {
  margin-bottom: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.history img {
  margin-right:30px;
  margin-bottom:30px;
}


// BOOTSTRAP EDIT
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 { padding-left: 10px; padding-right: 10px; }
.row { margin-left: -10px; margin-right: -10px; }
.container { padding-left: 10px; padding-right: 10px; }

@media (min-width: 1640px) {
  .container { width: 1640px; }
}

