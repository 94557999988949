.staff-detail__info {
  margin-bottom: 30px;
  &:before, &:after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}

.staff-detail__picture {
  float: left;
  margin: 0 20px 20px 0;
}

.staff-detail__name {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 52px;
  font-family "Times New Roman", serif;
  color: black;
}

/*.staff-detail__text {
  color: #7C7C7C;
  font-size: 22px;
  line-height: 28px;
}*/

.staff-detail__pin {
  padding-top: 25px;
}

@media screen and (max-width: 991px) {
  .staff-detail__picture {
    float: none;
    margin: 20px auto;
  }
  
  .staff-detail__name {
    text-align: center;
  }
}

@media screen and (max-width: 960px) {
  .staff {
    padding-top:20px;
    padding-bottom:40px;
  }
  .staff-list {
    padding-top:30px;
  }
}

@media screen and (max-width: 767px) {
  .staff-detail__text .staff-detail__name {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .staff-detail__name-small {
    display: none;
  }
}