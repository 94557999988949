.about-list {
  padding-bottom: 50px;
  position: relative;
  .row {
    margin-bottom: -50px;
  }

  &:after {
    content: '';
    display: block;
    width: 84px;
    height: 90px;
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: 0;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xNjM1NyAtMTI2NTUuOTM0IDEyNy41NzkgMTM3LjI1Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDpub25lO3N0cm9rZTojYjhhMWM2O3N0cm9rZS13aWR0aDo3cHg7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNMCwwVjEyNC4wNzlIMTMzLjc1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTYzNTcgLTEyNTIyLjE4NCkgcm90YXRlKC05MCkiLz48L3N2Zz4=');
  }
}

.about-item {
  margin-bottom: 50px;
  &:nth-child(odd) {
    .about-item__link { padding: 30px 10px 0 30px; }
    .about-item__link img { border-radius: 12px 0 12px 0; }
    .about-item__link:before { left: 0; border-radius: 12px 0 12px 0; }
    .about-item__link:after { left: 30px; border-radius: 12px 0 12px 0; }
    .about-item__name { left: 30px; border-bottom-right-radius: 12px;}
  }
  &:nth-child(even) {
    .about-item__link { padding: 30px 30px 0 10px; }
    .about-item__link img { border-radius: 0 12px 0 12px; }
    .about-item__link:before { right: 0; border-radius: 0 12px 0 12px; }
    .about-item__link:after { right: 30px; border-radius: 0 12px 0 12px; }
    .about-item__name { left: 10px; border-bottom-left-radius: 12px; }
  }
}

.about-item__link {
  display: block;
  position: relative;
  z-index: 1;
  /*&:before {
    content: '';
    display: block;
    width: 660px;
    height: 350px;
    background $BLUE_LIGHT;
    position: absolute;
    z-index: -1;
    top: 0;
  }*/
  /*&:after {
    content: '';
    position: absolute;
    top: 30px;
    z-index 1;
    display: block;
    width: calc(100% - 40px);
    height: calc(100% - 30px);
    background: #000000;
    opacity: 0;
    transition: opacity .3s ease;
  }*/

  &:hover {
    &:after { opacity: .5; }
    .about-item__name {
      color: #fff;
      background: rgba(#9972B8, .9);
    }
  }
}

.about-item__img {
  position: relative;
  z-index: 0;
}

.about-item__name {
  background: rgba(255,255,255,.5);
  position: absolute;
  z-index: 10;
  bottom: 0;
  height: 95px;
  width: calc(100% - 40px);
  text-align: right;
  padding: 0 45px;
  color: black;
  font-size: 42px;
  font-family 'Times New Roman', serif;
  text-transform: uppercase;
  transition: color .3s ease, background .3s ease;
  span {
    position: absolute;
    top: 50%;
    right: 45px;
    transform: translateY(-50%);
    transition: color .3s ease, background .3s ease;
  }
}

.about-item .about-item__link:before {
  content: '';
  display: block;
  width: 80%;
  height: 80%;
  background $BLUE_LIGHT;
  position: absolute;
  z-index: -1;
  top: 0;
}

.text-card:nth-child(odd) .text-card__img:before {
  left: 10px;
  border-radius: 12px 0 12px 0;
}

.text-card:nth-child(even) .text-card__img:before {
  right: 10px;
  border-radius: 0 12px 0 12px;
}

@media screen and (max-width: 1024px) {
  .about-item__name {
    font-size: 25px;
  }
}

@media screen and (max-width: 960px) {
  .about-list {
    padding-top:50px;
  }
  .history {
    padding-top:30px;
  }
}

@media screen and (max-width: 767px) {
  .about-list:after {
    content: none;
  }
}

@media screen and (max-width: 650px) {
  .about-item__name {
    font-size: 16px;
    height: 70px;
  }
  .about-item:nth-child(odd) .about-item__link:before,
  .about-item:nth-child(even) .about-item__link:before {
    top:10px;
  }
  .about-item:nth-child(odd) .about-item__link:before{
    left:10px;
  }
  .about-item:nth-child(even) .about-item__link:before{
    right:10px;
  }
}

@media screen and (max-width: 420px) {
  .about-item__name {
    height: 50px;
  }
}