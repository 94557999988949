.page-header {
  display: table;
  width: 100%;
  position: relative;
  height: 100px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 0;
  margin: 0;
  border-bottom: 0;
  .title {
    margin-bottom: 0;
    /*position: absolute;
    width: 100%;
    top: 40%;
    text-align: center;*/
    display: table-cell;
    vertical-align: middle;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.page-header img {
  width:100%;
}

@media screen and (max-width: 1280px) {
  /*.page-header {
    height:261px;
  }*/

  .page-header .title {
    font-size:38px;
    font-weight:400;
  }
}

@media screen and (max-width: 1024px) {
  /*.page-header {
    height:203px;
  }
  .page-header .title {
    font-size:32px;
  }*/

}

@media screen and (max-width: 650px) {
  /*.page-header {
    height:152px;
  }*/
  .page-header .title {
    font-size:28px;
  }
}

@media screen and (max-width: 600px) {
  /*.page-header {
    height:64px;
  }*/
  .page-header .title {
    font-size:18px;
  }
}
@media screen and (max-width: 440px){
  .page-header .title {
    top: 20%;
  }
}

