.footer-logo {
  margin-bottom: 20px;
}

.footer-logo__link {
  display: block;
  max-width: 300px;
  margin: 0 auto;
  opacity: 1;
  transition: opacity .3s ease;
  &:hover {
    opacity: .5;
  }
}