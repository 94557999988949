.nav {

}

.nav__list {
  list-style-type: none;
  margin: 0;

  &--horizontal {
    text-align: center;
    line-height: $FONT-SIZE_MEDIUM;
  }
  &--footer {
    line-height: 40px;
    .nav__link {
      color: $GREY;
      &:focus {
        color: $GREY;
      }
      &:hover {
        color: white;
      }
    }
  }
}


.nav__list-item {
  display: inline-block;
  font-size: 16px;

  &.on {
    .nav__link {
      color: $LINK-COLOR;
      font-weight:700;
    }
  }
}


.nav__link {
  padding-left: 18px;
  padding-right: 18px;
  color: $FONT-COLOR_DEFAULT;
  text-transform: uppercase;
  &:focus {
    color: $FONT-COLOR_DEFAULT;
  }
  &:hover {
    color: $LINK-COLOR;
    font-weight: 700;
    text-decoration: none;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 999;
}

.dropdown-content li {
  list-style: none;
}

.dropdown-content li a {
  float: none;
  color: #222;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content li a:hover {
  color: #72448d;
  font-weight:700;
}

.nav__list-item:hover .dropdown-content {
  display: block;
}


@media screen and (max-width: 1640px) {
  .nav__list-item {
    font-size:16px;
  }
  .nav__link {
      padding-left:5px;
      padding-right:5px;
    }
}