.license {
  margin-top: 0;
  margin-bottom: 10px;
  background-image url('../images/license.jpg');
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  min-height: 440px;
  padding-top: 50px;
  padding-bottom: 30px;
  position: relative;
}

.license__anons {
  /*position: auto;
  position: initial;*/
  padding-right 50px;
  padding-bottom:40px;
}


.license__text {
  /*position: absolute;
  left: calc(50% - 730px);
  top: 50%;
  width: 550px;
  transform: translateY(-50%);*/
  color: #fff;
  text-align: right;
  font-family: 'Times New Roman', serif;
  .text1 {
    font-size: 72px;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  .text2 {
    font-size: 42px;
    padding-left:70px;
  }
}

.license-gallery {
  /*padding-left: 120px;*/
  .staff-sertificate__list {
    height: auto;
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .staff-sertificate__item {
    width: 226px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
  }
}


@media screen and (max-width: 1280px) {
  .license__text .text1 {
    font-size: 55px;
  }
  .license__text .text2 {
    font-size: 35px;
  }
  .license {
    margin-bottom:0;
  }
}

@media screen and (max-width: 1200px){
  .license__text {
    text-align: center;
  }

}

@media screen and (max-width: 1024px) {
  .license__text .text1 {
    font-size: 40px;
  }
  .license__text .text2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 1023px) {
  .license__text .text1,
  .license__text .text2 {
    padding:0;
    text-align: center;
  }
  .license__anons {
    padding:0;
    padding-bottom:37px;
  }
}

@media screen and (max-width: 650px) {
  .license__text .text1 {
    font-size: 30px;
    margin-bottom:10px;
  }
  .license__text .text2 {
    font-size: 17px;
  }
}

@media screen and (min-width: 1024px) {
  .license__anons {
    width:41%;
  }
  .license-gallery {
    width:58%;
  }

  /*.staff-sertificate__list {
    flex-wrap: nowrap !important;
  }*/
  .license__text .text1,
  .license__text .text2 {
    text-align: right !important;
  }

}