.footer-contacts {
  text-align: center;
  font-size: 20px;
  color: $GREY;
  a {
    font-size: 25px;
    color: $GREY;
  }
}

@media screen and (max-width: 430px){
  .footer-contacts {
    font-size:14px;
  }
  .footer-contacts a {
    font-size:16px;
  }
}