.staff-sertificate {
  background $BLUE_LIGHT;
  padding: 30px 105px;
}

.staff-sertificate__title {
  font-size: 52px;
  font-family "Times New Roman", serif;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.staff-sertificate__list {
  display flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 -33.5px;
  height: 346px;
  overflow: hidden;
  &:before, &:after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}

.staff-sertificate__item {
  /*width: 20%;
  padding: 0 33.5px;*/
  margin-bottom: 20px;
  padding: 0 10px;
  height: 100%;
  display: table;
  /*float: left;*/
  a {
    /*border: 5px solid #fff;*/
    display: table-cell;
    vertical-align: middle;
  }
}

.staff-sertificate__item a img {
  border: 5px solid #fff;
}

.staff-sertificate__toggle {
  margin-top: 30px;
  color: $VIOLET;
  line-height: 40px;
  border: 0;
  background transparent;
  padding-left: 50px;
  position: relative;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  display: block;
  margin-left: auto;
  margin-right: auto;
  outline: none;
  &:before {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: $VIOLET_LIGHT;
    background-size: 20px;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  &:hover {
    text-decoration: underline;
    &:before {
      background-color: $VIOLET;
    }
  }

  &.is-open {
    &:before {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xNDkzMC4zNTQgLTgyMDAuNzA3IDIwLjcwNyAyMC43MDciPiAgPGRlZnM+ICAgIDxzdHlsZT4gICAgICAuY2xzLTEgeyAgICAgICAgZmlsbDogbm9uZTsgICAgICAgIHN0cm9rZTogI2ZmZjsgICAgICB9ICAgIDwvc3R5bGU+ICA8L2RlZnM+ICA8ZyBpZD0iR3JvdXBfOTMiIGRhdGEtbmFtZT0iR3JvdXAgOTMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjgxMCAtODU5MCkgcm90YXRlKDkwKSI+ICAgIDxwYXRoIGlkPSJQYXRoXzU3IiBkYXRhLW5hbWU9IlBhdGggNTciIGNsYXNzPSJjbHMtMSIgZD0iTTcwNjAsNTU1MGwtMTAsMTAsMTAsMTAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02NjYwIC0zNDUwKSIvPiAgICA8cGF0aCBpZD0iUGF0aF81OCIgZGF0YS1uYW1lPSJQYXRoIDU4IiBjbGFzcz0iY2xzLTEiIGQ9Ik03MDcwLDU1NjBoLTIwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjY2MCAtMzQ1MCkiLz4gIDwvZz48L3N2Zz4=');
    }
  }

  &.is-closed {
    &:before {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xNDkzMC4zNTQgLTUyNDAgMjAuNzA3IDIwLjcwNyI+ICA8ZGVmcz4gICAgPHN0eWxlPiAgICAgIC5jbHMtMSB7ICAgICAgICBmaWxsOiBub25lOyAgICAgICAgc3Ryb2tlOiAjZmZmOyAgICAgIH0gICAgPC9zdHlsZT4gIDwvZGVmcz4gIDxnIGlkPSJHcm91cF85MyIgZGF0YS1uYW1lPSJHcm91cCA5MyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3MDMwIC00ODMwKSByb3RhdGUoLTkwKSI+ICAgIDxwYXRoIGlkPSJQYXRoXzU3IiBkYXRhLW5hbWU9IlBhdGggNTciIGNsYXNzPSJjbHMtMSIgZD0iTTcwNjAsNTU1MGwtMTAsMTAsMTAsMTAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02NjYwIC0zNDUwKSIvPiAgICA8cGF0aCBpZD0iUGF0aF81OCIgZGF0YS1uYW1lPSJQYXRoIDU4IiBjbGFzcz0iY2xzLTEiIGQ9Ik03MDcwLDU1NjBoLTIwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjY2MCAtMzQ1MCkiLz4gIDwvZz48L3N2Zz4=');
    }
  }
}

@media screen and (max-width: 1280px) {
  .staff-sertificate__title {
    font-size: 45px;
  }
}

@media screen and (max-width: 1024px) {
  .staff-sertificate__title {
    font-size: 30px;
  }

  .staff-sertificate__item {
    width:20%;
  }

  .staff-sertificate__item a img {
    border: none;
  }
}

@media screen and (max-width: 960px) {
  .staff-sertificate__item {
    padding: 0 5px;
    width:150px;
  }
}

@media screen and (max-width: 505px) {
  .staff-sertificate__toggle {
    line-height:20px;
  }
  .staff-sertificate {
    padding: 30px;
  }
}

@media screen and (max-width: 460px) {
  .license-gallery .staff-sertificate__list .staff-sertificate__item {
    width:160px;
  }
  .license .license-gallery .staff-sertificate__list {
    justify-content: flex-start;
  }

  .staff-sertificate {
    padding-left:0;
    padding-right:0;
    margin-left:-10px;
    margin-right: -10px;
  }
  .staff-sertificate__list {
    margin:0;
  }
}

@media screen and (max-width: 320px) {
  .staff-sertificate__toggle {
    padding-left:5px;
  }

  .staff-sertificate__toggle:before {
    left -45px;
  }

}
