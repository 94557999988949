$TEXT-COLOR = #7C7C7C;
$OFFSET = 30px;

.contacts {
  color: $TEXT-COLOR;
  padding-bottom: $OFFSET;
  margin-top:28px;
}

.contacts__left {
  font-family:$FONT_DEFAULT;
}

.contacts__address {
  margin-bottom: $OFFSET;
}

.contacts__address-label {
  font-weight: bold;
  text-transform: uppercase;
}

.contacts__phone {
  margin-bottom: $OFFSET;
}

.contacts__phone-label {
  font-weight: bold;
}

.contacts__worktime-label {
  font-weight: bold;
  margin-bottom: $OFFSET;
}

.contacts__button {
  display: block;
  /*width: 400px;*/
  width: 242px;
  margin: 0 auto;
  /*height: 90px;*/
  height: 68px;
  border: 0;
  background $VIOLET_LIGHT;
  border-radius: 4px;
  font-size: 20px;
  color: white;
  font-size: 20px;
  font-family "Tahoma", Arial, sans-serif;
  margin-bottom: 30px;
  margin-top:4px;
  &:hover {
    background $VIOLET;
  }
}

@media screen and (max-width: 960px) {
  .contacts__button {
    height:60px;
    width:206px;
  }
  .contacts .social__list-item a.social__link {
    width:58px;
    height:58px;
  }
}

@media screen and (max-width: 767px){
  .contacts__left {
    padding-left:20px;
    font-size:17px;
  }

  .contacts__button {
    margin-top:20px;
    width:250px;
  }

  .contacts .contacts__right .social__list-item {
    margin-right: 33px;
  }
  .contacts .contacts__right .social__list-item:last-child {
    margin-right:0;
  }

}


@media screen and (max-width: 649px){
  .contacts__button {
    width:100%;
  }
}
